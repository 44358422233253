import { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AppRouter } from './components/AppRouter';
import { AuthProvider } from './contexts/AuthContext';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fff', // Dark background color
      paper: '#ccc' // Optional, for components like cards or dialogs
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1a1a1a', // Dark background color
      paper: '#2b2b2b' // Optional, for components like cards or dialogs
    },
  },
});

function App() {
  console.log('App')
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    document.title = 'tasunk'
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  // const toggleTheme = () => {
  //   setDarkMode(!darkMode);
  //   localStorage.setItem('darkMode', !darkMode);
  //   console.log('toggleTheme', darkMode)
  // };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <AuthProvider>
        <AppRouter />
        {/* <Button onClick={toggleTheme}>Toggle Darkmode</Button> */}
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App;
