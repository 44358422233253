import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './Login';
import { Register } from './Register';
import ProtectedRoute from './ProtectedRoute';
import logo from '../images/logo1.jpeg';
import BetaSignupForm from './BetaSignupForm';
import Logout from './Logout';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Dashboard from './Dashboard';
import UserTaskList from './UserTask/UserTaskList';

export function AppRouter() {
    console.log('AppRouter')
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (user) {

        } else {
            
        }
    }, [user]);
    
    return (
        <Router>
            {/* <Container maxWidth="false" 
                sx={{ 
                    backgroundColor: '#111', 
                    color: '#3f51b5', 
                    '& a': {
                        color: '#3f51b5',
                        textDecoration: 'none',
                        '&:hover': {
                            color: '#172238',
                        }    
                    }
                }}>
                <Link to="/">tasunk</Link>|
                { user ? 
                    <>
                    <Link to="/dashboard">dasboard</Link>
                    <Link to="/tasks">tasks</Link>
                    <Link to="/logout">logout</Link>
                    </>
                 :
                    <>
                    <Link to="/login">login</Link>|
                    <Link to="/register">register</Link>
                    </>
                }
            </Container> */}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/logout" element={<Logout />} />
                { user ? 
                    <>
                    <Route 
                        path="/dashboard" 
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route 
                        path="/tasks" 
                        element={
                            <ProtectedRoute>
                                <UserTaskList />
                            </ProtectedRoute>
                        }
                    />
                    </>
                    : <></>
                }
                
                {/* Catch-all route */}
                <Route path="*" element={
                    <div className="App">
                        <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                        </header>
                        <main >
                            <BetaSignupForm />
                        </main>
                    </div>
                } />
            </Routes>
        </Router>
    );
}