import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Container, Typography, TextField, FormControlLabel, Checkbox, Box,
  List,
  ListItem,
  ListItemText,
  Divider
 } from '@mui/material';
import { useFetch } from '../../hooks/useFetch';
import { functions, auth, getDocs, doc, db, updateDoc, collection, query, where, } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import BetaSignupForm from '../BetaSignupForm';

const UserTaskRow = ({task}) => {
    const [loading, setLoading] = useState(false);
    const [showLogs, setShowLogs] = useState(false);

    const onExecute = async (taskId) => {
        console.log('onExecute', taskId)
        setLoading(true);
        try {
            const callable = httpsCallable(functions, 'executeTask');
            const response = await callable({taskId});
            console.log('executeTask res', JSON.stringify(response, null, 2));
        } catch (err) {
            console.log(err.message || 'An error occurred while fetching the profile.');
        } finally {
          setLoading(false);
        }
      };
  
      const onDelete = (taskId) => {
        console.log('onDelete', taskId)
        setLoading(true);
      };
  
      const onEdit = (taskId) => {
        console.log('onEdit', taskId)
        setLoading(true);
      };
      
    return (
        <Container maxWidth="sm">
            <ListItem 
              alignItems="flex-start"
              sx={{ padding: 2 }}
            >
              <ListItemText
                primary={task.name}
                secondary={
                  <>
                    <Typography
                      sx={{ display: 'inline', fontWeight: 'bold' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      ID:
                    </Typography>
                    {` ${task.id}`}
                    <br />
                    <Typography
                      sx={{ display: 'inline', fontWeight: 'bold' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Status:
                    </Typography>
                    {` ${task.status}`}
                    <br />
                    <Typography
                      sx={{ display: 'inline', fontWeight: 'bold' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Mode:
                    </Typography>
                    {` ${task.mode}`}
                    <br />
                    <Typography
                      sx={{ display: 'inline', fontWeight: 'bold' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Prompt:
                    </Typography>
                    {` ${task.prompt}`}
                    
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={() => setShowLogs(!showLogs)}
                        sx={{ marginRight: 1 }}
                        disabled={loading || !task.log?.length }
                        >
                        Show Logs
                        </Button>
                  </>
                }
              />
              <div>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={() => onExecute(task.id)}
                  sx={{ marginRight: 1 }}
                  disabled={loading}
                >
                  Execute
                </Button>
                {/* <Button 
                  variant="outlined" 
                  color="secondary" 
                  onClick={() => onEdit(task.id)}
                  sx={{ marginRight: 1 }}
                  disabled={loading}
                >
                  Edit
                </Button> */}
                {/* <Button 
                  variant="outlined" 
                  color="error" 
                  onClick={() => onDelete(task.id)}
                  disabled={loading}
                >
                  Delete
                </Button> */}
              </div>
            </ListItem>
            {showLogs ? (
                <List>
            
                {task.log.map(log => (
                  <React.Fragment key={log.id}>
                    <ListItem 
                      alignItems="flex-start"
                      sx={{ padding: 2 }}
                    >
                      <ListItemText
                        primary="Log"
                        secondary={
                          <>
                          
                            <>
                            <Typography
                              sx={{ display: 'inline', fontWeight: 'bold' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              ID: 
                            </Typography>
                            {` ${log.id}`}
                            <br />
                            <Typography
                              sx={{ display: 'inline', fontWeight: 'bold' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Date: 
                            </Typography>
                            {` ${log.createdAtHuman}`}
                            <br />
                            <Typography
                              sx={{ display: 'inline', fontWeight: 'bold' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Message:
                            </Typography>
                            {` ${log.message}`}
                            </>
                          </>
                        }
                      />
                    </ListItem>
                    </React.Fragment>
                    ))}
                    </List>
            ) : (<></>)}
        </Container>
    );
}

export default UserTaskRow;