import React, { createContext, useState, useEffect } from 'react';
import { 
  auth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  sendEmailVerification,
  googleProvider, 
  // TwitterAuthProvider, 
  signInWithPopup } from '../firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const register = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const sendEmailVerify = () => {
    return sendEmailVerification(user);
  };

  const signInWithGoogle = async () => {
    return signInWithPopup(auth, googleProvider);
  };

  // const signInWithTwitter = async () => {
  //   try {
  //     await signInWithPopup(auth, TwitterAuthProvider);
  //   } catch (error) {
  //     console.log('Twitter sign-in error:', error);
  //   }
  // };

  const value = {
    user,
    login,
    register,
    logout,
    sendEmailVerify,
    signInWithGoogle,
    //signInWithTwitter
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};