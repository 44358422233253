import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions, auth } from '../firebase';

export function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const { register, user, signInWithGoogle } = useContext(AuthContext);
  const navigate = useNavigate();
 
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    } else {
      setLoading(false);
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await register(email, password);
    } catch (error) {
      console.log("Register failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSignInWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithGoogle();
    } catch (error) {
      console.log('Google sign-in error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Typography gutterBottom>
          Register or <Link to="/login">log in here</Link>.
        </Typography>

        <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
        />

        <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
        />
        
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 0 }}
            disabled={loading}
        >
          Sign Up
        </Button>

        <Button 
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 0 }}
          disabled={loading}
          onClick={onSignInWithGoogle}>
          Sign Up with Google
        </Button>
        {/* <Button onClick={onSignInWithTwitter}>Sign in with X</Button> */}

      </Box>
    </Container>
  );
}