import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Container, Typography, TextField, FormControlLabel, Checkbox, Box,
  List,
  ListItem,
  ListItemText,
  Divider
 } from '@mui/material';
import { useFetch } from '../../hooks/useFetch';
import { functions, auth, getDocs, doc, db, updateDoc, collection, query, where, } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import BetaSignupForm from '../BetaSignupForm';
import UserTaskRow from './UserTaskRow';

function UserTaskList() {
    const { user, sendEmailVerify } = useContext(AuthContext);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (user) {
            const fetchTasks = async () => {
                try {
                  setLoading(true);
                    const q = query(collection(db, "tasks"), where("userId", "==", user.uid));
                    const querySnapshot = await getDocs(q);
                    const taskList = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                    setTasks(taskList);
                } catch (err) {
                  setError('Failed to fetch tasks: ' + err.message);
                } finally {
                  setLoading(false);
                }
            };
          
            fetchTasks();
        }
    }, [user]);


    return (
      <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Tasks
      </Typography>
      <List>
        {tasks.map(task => (
          <React.Fragment key={task.id}>
            <UserTaskRow task={task}></UserTaskRow>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </Container>
    );
}

export default UserTaskList;