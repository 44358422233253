import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Button, Container, Typography, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useFetch } from '../hooks/useFetch';
import { functions, auth, getDoc, doc, db, updateDoc } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import BetaSignupForm from './BetaSignupForm';
import UserTaskList from './UserTask/UserTaskList';

function Dashboard() {
    console.log('Dashboard load');
    const { user, sendEmailVerify } = useContext(AuthContext);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userDoc, setUserDoc] = useState(null);

    useEffect(() => {
        if (user) {
            console.log('dashboard user', user);
            // async function testAuth() {
            //     console.log('test auth user', user);
            //     try {
            //         await auth.currentUser.getIdToken(true);
            //         const callable = httpsCallable(functions, 'testAuth');
            //         const response = await callable({});
            //         console.log('testAuth res', JSON.stringify(response, null, 2));
            //         setResult(response.data);
            //     } catch (err) {
            //         setResult(err.message || 'An error occurred while fetching the profile.');
            //     }
            // }
            // testAuth();

            const fetchDocument = async () => {
                console.log('dashboard fetch doc', user.uid);
                try {
                    setLoading(true);
                    const docRef = doc(db, `users/${user.uid}`);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setUserDoc(docSnap.data());
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such user document!");
                        setUserDoc(null);
                    }
                } catch (error) {
                    console.log("Error fetching user document: ", error);
                    setError(error);
                } finally {
                    setLoading(false);
                }
            };
          
            fetchDocument();

        }
    }, [user]);

    const onSendEmailVerification = async () => {
        setLoading(true);
        await sendEmailVerify()
        setLoading(false);
    };

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setUserDoc(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const time = new Date();
            setLoading(true);
            await updateDoc(doc(db, `users/${user.uid}`), {
                lastName: userDoc.lastName,
                firstName: userDoc.firstName,
                emailOptIn: userDoc.emailOptIn,
                displayName: userDoc.displayName,
                updatedAt: time.getTime(),
		        updatedAtHuman: time.toString(),
            });
            setLoading(false);
        } catch (error) {
            console.log('Error updating document: ', error);
        }
    };
    

    // const config = {
    //     apiBaseUrl: true || process.env.NODE_ENV === 'production' 
    //       ? 'https://testfunction-uiwihxgvbq-uc.a.run.app/' 
    //       : 'http://127.0.0.1:5001/tasunk-prod/us-central1/testFunction'
    // };
    // console.log('config dashboard', config, process.env);

    // const { data, error, loading } = useFetch(config.apiBaseUrl);
    
    // if (loading) {
    //     console.log('loading api', loading)
    //     return <div>Loading...</div>
    // }
    // if (error) {
    //     console.log('api error', error)
    //     return <div>Error: { error.message}</div>
    // }

    return (
        <Container maxWidth="sm">
            <Typography gutterBottom>
                Dashboard {JSON.stringify(userDoc, null, 2)} 
            </Typography>
            <Button disabled={user?.emailVerified || loading} onClick={onSendEmailVerification}>
                Send Verification Email
            </Button>
            { userDoc ? (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Typography gutterBottom>
                    User Profile
                </Typography>
                <TextField
                    label="Email"
                    name="email"
                    value={userDoc.email}
                    fullWidth
                    margin="normal"
                    readOnly={true}
                />
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={userDoc.lastName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    readOnly={loading}
                    disabled={loading}
                    required
                />
                <TextField
                    label="First Name"
                    name="firstName"
                    value={userDoc.firstName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    readOnly={loading}
                    disabled={loading}
                    required
                />
                <TextField
                    label="Display Name"
                    name="displayName"
                    value={userDoc.displayName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    readOnly={loading}
                    disabled={loading}
                    required
                />
                <FormControlLabel
                    control={
                    <Checkbox 
                        checked={userDoc.emailOptIn} 
                        onChange={handleChange} 
                        name="emailOptIn" 
                        readOnly={loading}
                        disabled={loading}
                    />
                    }
                    label="Receive Updates"
                />
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
                </Box>
            ) : (
                <>Loading...</>
            )}
            
        </Container>
    );
}

export default Dashboard;

// {
//     "lastName": "",
//     "firstName": "",
//     "emailOptIn": true,
//     "displayName": "",
//   }