import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Container, Typography } from '@mui/material';

function Logout() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const logoutAndRedirect = async () => {
      try {
        await logout();
        navigate('/', { replace: true });
      } catch (error) {
        console.log("Logout failed:", error);
      }
    };

    // Only run this if we've just navigated to this route
    if (location.pathname === '/logout') {
      logoutAndRedirect();
    }
  }, [location.pathname, logout, navigate]);

  return (
    <Container maxWidth="sm">
      <Typography gutterBottom>
        Logging you out...
      </Typography>
    </Container>
  );
}

export default Logout;