// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDoc, getDocs, doc, setDoc, updateDoc, query, where } from 'firebase/firestore';
import { getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut, 
  sendEmailVerification,
  GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2TCnes9_VSWqkXbebJFUYTaQbCYbvFEM",
  authDomain: "tasunk-prod.firebaseapp.com",
  projectId: "tasunk-prod",
  storageBucket: "tasunk-prod.firebasestorage.app",
  messagingSenderId: "1090594162150",
  appId: "1:1090594162150:web:9316b495f16afb17c9c227",
  measurementId: "G-QDN4ESJZQ2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

const googleProvider = new GoogleAuthProvider();

export {
    db,
    auth, 
    addDoc, 
    getDoc,
    setDoc,
    getDocs,
    updateDoc,
    doc,
    collection, 
    analytics,
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword, 
    signOut,
    functions,
    sendEmailVerification,
    googleProvider,
    signInWithPopup,
    query, 
    where
};