import React, { useState } from 'react';
import { db, addDoc, collection } from '../firebase';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

function BetaSignupForm() {
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await addDoc(collection(db, "BetaSignupRequests"), {
        email: email,
        reason: reason,
        timestamp: new Date(),
      });
      setShowForm(false);
      setEmail('');
      setReason('');
      
    } catch (err) {
      setError("Failed to sign up for beta. Please try again.");
      console.log("Error adding document: ", err);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
        {showForm ? (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Typography variant="h3" gutterBottom>
                Beta Sign Up
                </Typography>
                <Typography variant="h4" gutterBottom>
                We will contact you about access to our beta release of tasunk
                </Typography>
                
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    multiline
                    rows={4}
                    required
                    fullWidth
                    id="reason"
                    label="What will you use Tasunk for?"
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Sign Up for Beta'}
                </Button>
                {error && <Typography color="error">{error}</Typography>}
            </Box>
        ) : (
            <Typography sx={{ color: 'green', mt: 2 }} variant="h6">
            Thanks for signing up! We'll notify you soon.
            </Typography>
        )}


    </Container>
  );
}

export default BetaSignupForm;